import React from "react"

const Footer = ({ heroImage }) => {
  
  return (
    <footer>
      <div className="content has-text-centered">
        
          Built by <a href="https://www.webdog.co.nz">Webdog - </a> 
        
        © 2021 
      </div>
    </footer>
  )
}

export default Footer
